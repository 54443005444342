export const environment = {
  production: true,
  version: 'e62d8596c66d6b1b1cc6bfcd08b13d1b04bc1117',
  googleMapsApiKey: 'AIzaSyAiv8XnKboepzWpLJW_dB6H4glm8WFDzTE',
  weatherApiKey: 'ecd5bb0efd934aea9a5122330232811',
  weatherApiUrl: 'https://api.weatherapi.com/v1',
  apiBaseUrl: '/api',
  apiBaseAdminUrl: '/api/admin',
  authUrl: 'https://brainapp.us.auth0.com',
  apiAirQualityUrl: 'https://airquality.googleapis.com/v1',
  authClientId: 'e0jIDxskLX0vlvRbwQhR50XUHozEnui0',
  authGrantType: 'password',
};
